import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function ProfileMenu() {
  const state = useSelector((state) => state);
  const history = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch({
      type: "LOGOUT",
    });
    history("/");
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = ["User Profile", "Request/Feedback", "Change Password"];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <div>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title={state.data.name}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              sx={{ bgcolor: blue[800], width: 30, height: 30 }}
              alt="Remy Sharp"
              src=""
            />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={handleCloseUserMenu}>
            <Typography textAlign="center" style={{ color: "blue" }}>
              <small>{state.data.name}</small>
            </Typography>
          </MenuItem>
          {settings.map((setting) => (
            <Link
              to={`/${setting}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center" style={{ fontSize: "13px" }}>
                  {setting}
                </Typography>
              </MenuItem>
            </Link>
          ))}
          <MenuItem onClick={handleCloseUserMenu}>
            <Typography textAlign="center">
              <div className="btn btn-sm" id="bgcolor" onClick={logout}>
                <i className="bx bx-user"></i> Logout
              </div>
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
}
