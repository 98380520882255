import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Stockrequest from "./Stockrequest";
import Wishlist from "./Wishlist";
import "../styles/reqandfeed.css"
import { useSelector } from "react-redux";
import Feedback from "./Feedback";

function Reqandfeed() {
  const [isActive, setActive] = useState("false");
  const state = useSelector((state) => state);
  const history = useNavigate();
  useEffect  (() => {
    if (state.isAuthenticate === false) history("/");
  });
  const ToggleClass = (e) => {
    document.getElementById(e.target.id).style.borderBottom = "1px solid black"
    setActive(!isActive);
  };
  return (
    <>
      <div className="d-flex justify-content-start raf_nav container p-1 mb-4">
        <Link to="/Request/Feedback/" className="raf_tag" onClick={(e)=>ToggleClass(e)}>
          <div className="raf_activemenu">Wish List</div>
        </Link>
        <Link to="/Request/Feedback/stockrequest" className="raf_tag">
          <div className="raf_activemenu">Stock Request</div>
        </Link>
        <Link to="/Request/Feedback/feedback" className="raf_tag">
          <div className="raf_activemenu">Feedback</div>
        </Link>
      </div>
      <Routes>
        <Route index element={<Wishlist />} />
        <Route path="stockrequest" element={<Stockrequest />} />
        <Route path="feedback" element={<Feedback />} />
      </Routes>
    </>
  );
}

export default Reqandfeed;
