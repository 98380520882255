import axios from "axios";
import React, { useEffect, useState } from "react";
import "../styles/products.css";
import sup_img from "../img/supplier_img.jfif";
import { useLocation } from "react-router-dom";

function Products({ handleClickOpen }) {
  const location = useLocation();
  const id = location.state && location.state[0];
  const name = location.state && location.state[1];
  const [data, setData] = useState("");
  const [prosearch, setProSearch] = useState(null);

  useEffect(() => {
    if (prosearch === null) {
      var urls = `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=gethomeAppliances&type_id=${id}`;
    } else {
      urls = `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getallitemsbasedsearch&search=${prosearch}&&typeid=${id}`;
    }
    axios({
      url: urls,
    })
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, prosearch]);
  const url = `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getProductStock&item_id`;
  return (
    <>
      <div className="d-flex justify-content-between container pro_head">
        <div className="prohead">{name}</div>
        <div class="row align-items-center">
          <label for="exampleDataList" class="col-sm-4 col-form-label">
            Product&nbsp;Name
          </label>
          <div class="col-sm-8">
            <input
              class="form-control"
              id="exampleDataList"
              placeholder="Type to search..."
              onChange={(e) => setProSearch(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="productdiv">
        {data &&
          data.map((value) => {
            return (
              <div key={value.ItemId} className="ss mb-4">
                <div className="p_img">
                  {/* <img alt="product" className="sup_img" src={`https://kpkbgcavadi.in/CRPF/PRODUCTS/${id}/${value.ItemId}.jpg`} /> */}
                  <img alt="product" className="pro_img" src={sup_img} />
                </div>
                <div className="btns mt-3">
                  <b
                    className={
                      value.stock === "0"
                        ? "text-light bg-danger p-1 rounded"
                        : "text-light bg-success p-1 rounded"
                    }
                  >
                    Stock:{" "}
                    <span

                    // className={
                    //   value.stock === "0" ? "text-danger" : "text-success"
                    // }
                    >
                      {value.stock}
                    </span>
                  </b>
                </div>
                <div className="code">{value.ItemId}</div>
                <div className="rate">
                  <b>MRP: </b>
                  {value.MRP}
                  <br></br>
                  <b>KPKB Rate: </b>
                  {value.SaleRate}
                  <br></br>
                  {/* <b  className={
                        value.stock === "0" ? "text-light bg-danger p-1 rounded" : "text-light bg-success p-1 rounded"
                      }>
                    Stock:{" "}
                    <span

                      // className={
                      //   value.stock === "0" ? "text-danger" : "text-success"
                      // }
                    >
                      {value.stock}
                    </span>
                  </b> */}
                </div>
                <div className="name">
                  <small>
                    <b>Brand:</b> {value.Brand}
                    <br></br>
                    <b>Name: </b>
                    <small>{value.ItemName}</small>
                  </small>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Products;
