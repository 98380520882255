import "../styles/login.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import loginimg from "../img/CRPF-Login.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const state = useSelector((state) => state);
  useEffect(() => {
    if (state.isAuthenticate) history("/dashboard");
  });
  const dispatch = useDispatch();
  const handlesubmit = () => {
    const loginarray = JSON.stringify([
      { username: username, password: password },
    ]);
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=login&loginarray=${loginarray}`,
    }).then(
      (res) => {
        const auth = res.data.data[0]["loginstatus"];
        if (auth === "Success") {
          dispatch({
            type: "LOGIN",
            payload: {
              usertype: res.data.data[0]["usertype"],
              code: res.data.data[0]["cpsno"],
              name:res.data.data[0]["name"]
            },
          });
          history("/dashboard");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const formvalidate = (e, arg) => {
    let x = document.getElementById(arg).value;
    if (x === "") {
      document.getElementById(arg).className =
        "is-invalid form-control mb-3 pr-5";
    } else {
      document.getElementById(arg).className = "form-control mb-3 pr-5";
    }
  };
  //caspercaan
  return (
    <div className="logindiv">
      <div>
        <img alt="login_img" className="loginimg" src={loginimg} />
      </div>
      <div className="border bg loginbg">
        <h1 className="mb-4 color">LOGIN</h1>
        {/* <form> */}
          <div className="form-group">
            <div>
              <label className="d-flex small" name="username">
                Username
              </label>
              <input
                type="text"
                className="form-control mb-3 pr-5"
                placeholder="Username"
                id="username"
                onBlur={(e) => {
                  formvalidate(e, "username");
                }}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div>
                <label className="d-flex small" name="Password">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control mb- pr-5"
                  placeholder="Password"
                  id="password"
                  onBlur={(e) => {
                    formvalidate(e, "password");
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <Link to="/forgetpage" className="atag">
              Forget password ?
            </Link>
          </div>
          <div className="d-flex justify-content-center align-items-baseline mt-4">
            <div className="flex-fill">
              <Link className="d-flex mt-2 atag" to="/signup">
                Sign up
              </Link>
            </div>
            <div>
              <input
                onClick={handlesubmit}
                className="btn shadow-none"
                id="bgcolor"
                type="submit"
                value={"Submit"}
              />
            </div>
          </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default Login;
