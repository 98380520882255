import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../styles/canteenstock.css";

function Canteenstock() {
  const [category, setCategory] = useState("");
  const [canteen, setCanteen] = useState("");
  const [canteenId, setCanteenId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [data, setData] = useState("");
  const state = useSelector((state) => state);
  const history = useNavigate();
  useEffect(() => {
    if (state.isAuthenticate === false) history("/");
  });
  useEffect(() => {
    try {
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=groupmaster`,
      }).then((res) => {
        setCategory(res.data.data);
      });
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getcanteendetailsmobile`,
      }).then((res) => {
        setCanteen(res.data.data);
        setCanteenId(res.data.data[0].locationid);
      });
      if (canteenId && categoryId) {
        axios({
          url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getproductsbylocation&locationid=${canteenId}&typeid=${categoryId}`,
        }).then((res) => {
          console.log(res.data.data);
          setData(res.data.data);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [canteenId, categoryId]);

  return (
    <>
      <div className="d-flex justify-content-around container mt-3">
        <div class="mb-3 row">
          <label for="canteenDataList" class="col-sm-3 col-form-label">
            Canteen
          </label>
          <div class="col-sm-9">
            <input class="form-control" disabled value="CRPF AVADI"></input>
            {/* <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setCanteenId(e.target.value)}
            >
              <option selected>select canteen</option>
              {canteen &&
                canteen.map((value) => {
                  return (
                    <option value={value.locationid}>
                      {value.locationname}
                    </option>
                  );
                })}
            </select> */}
          </div>
        </div>
        <div class="mb-3 row">
          <label for="categoryDataList" class="col-sm-3 col-form-label mr-4">
            Category
          </label>
          <div class="col-sm-9">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setCategoryId(e.target.value)}
            >
              <option selected>Select Category</option>
              {category &&
                category.map((value) => {
                  return (
                    <option value={value.GroupId}>{value.GroupName}</option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <table className="table container t_body table-striped">
        <thead className="t_head">
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Brand Name</th>
            <th scope="col">Itemid</th>
            <th scope="col">Item Name</th>
            <th scope="col">Stock</th>
            <th scope="col">Rate</th>
            <th scope="col">MRP</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((value) => {
              return (
                <tr>
                  <td className="counterCell"></td>
                  <td>{value.Brand}</td>
                  <td>{value.itemid}</td>
                  <td>{value.ItemName}</td>
                  <td>{value.stock}</td>
                  <td>{value.rate}</td>
                  <td>{value.MRP}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export default Canteenstock;
