import "../styles/signup.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Signup() {
  const [ismobiles, setIsmobiles] = useState(window.innerWidth < 700);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [usertype, setUsertype] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 700;
        if (ismobile !== ismobiles) setIsmobiles(ismobile);
      },
      false
    );
  }, [ismobiles]);
  const dispatch = useDispatch();
  const history = useNavigate();
  const handleChange = (event, arg) => {
    if (!event.target.validity.valid || event.target.value === "") {
      document.getElementById(arg).className = "is-invalid form-control";
    } else {
      document.getElementById(arg).className = "form-control";
    }
  };
  const formvalidate = () => {
    setError("");
    let list = [
      "Force",
      "name",
      "username",
      "password",
      "email",
      "address",
      "district",
      "usertype",
    ];
    list.forEach((states) => {
      let x = document.getElementById(states).value;
      if (x === "") {
        document.getElementById(states).className =
          "is-invalid form-control mb-2 pr-5 mb-sm-0";
      }
    });
    if (
      code &&
      name &&
      username &&
      password &&
      email &&
      address &&
      district &&
      usertype
    ) {
      const regarray = JSON.stringify([
        {
          gpfno: code,
          password: password,
          name: name,
          phoneno: username,
          mobileno: username,
          address: address,
          district: district,
          usertype: usertype,
          email: email,
        },
      ]);
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=registermobile&regarray=${regarray}`,
      })
        .then((res) => {
          const auth = res.data.registerstatus;
          if (auth === "1") {
            dispatch({
              type: "FROM",
              payload: {
                from:"signup"
              },
            });
            axios({
              url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=sendmsgmobile&mobileno=${res.data.username}`,
            }).then((data) => {
              dispatch({
                type: "SIGNUP",
                payload: {
                  code: code,
                  usertype: usertype,
                  mobile: res.data.username,
                },
              });
            });
            history("/otp");
          } else {
            setError(auth);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="d-flex justify-content-center mb-4">
      <div
        className="p-2 flex-fill"
        style={{ display: `${ismobiles ? "none" : ""}` }}
      >
        &nbsp;
      </div>
      <div className={`${ismobiles ? "flex-fill" : "flex-fill"}`}>
        <div className="border pb-3 pt-2 pr-3 pl-3 bg">
          <h1 className="color">Sign up</h1>
          <form>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="Force">
                  Force/PPO No.
                  </label>
                  <input
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="Force/PPO No."
                    id="Force"
                    // pattern="[A-Za-z]{1,20}"
                    onBlur={(e) => {
                      handleChange(e, "Force");
                    }}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </fieldset>
              </div>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="Name"
                    id="name"
                    pattern="[A-Za-z]{1,20}"
                    onBlur={(e) => {
                      handleChange(e, "name");
                    }}
                    onChange={(e) => setName(e.target.value)}
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="User name (Mobile No)">
                    User name (Mobile No)
                  </label>
                  <input
                    type="tel"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="User name"
                    pattern="[0-9]{10}"
                    id="username"
                    onBlur={(e) => {
                      handleChange(e, "username");
                    }}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </fieldset>
              </div>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="password">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="password"
                    id="password"
                    onBlur={(e) => {
                      handleChange(e, "password");
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="email id"
                    id="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    onBlur={(e) => {
                      handleChange(e, "email");
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </fieldset>
              </div>

              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="Address">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="Address"
                    id="address"
                    onBlur={(e) => {
                      handleChange(e, "address");
                    }}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="District">
                    District
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="District"
                    id="district"
                    onBlur={(e) => {
                      handleChange(e, "district");
                    }}
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </fieldset>
              </div>

              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="User type">
                    User type
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="User type"
                    id="usertype"
                    onBlur={(e) => {
                      handleChange(e, "usertype");
                    }}
                    onChange={(e) => setUsertype(e.target.value)}
                  />
                </fieldset>
              </div>
            </div>
            <div>{error !== null ? error : ""}</div>
          </form>
          <button
            className="btn shadow-none mt-3"
            type="submit"
            name="sign up"
            id="bgcolor"
            onClick={formvalidate}
          >
            Submit
          </button>
        </div>
      </div>
      <div
        className="p-2 flex-fill"
        style={{ display: `${ismobiles ? "none" : ""}` }}
      >
        &nbsp;
      </div>
    </div>
  );
}

export default Signup;
