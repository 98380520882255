import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../styles/feedback.css";
function Feedback() {
  const state = useSelector((state) => state);
  const [company, setCompany] = useState("");
  const [product, setProduct] = useState("");
  const [feedback, setFeedback] = useState("");
  const [serviceFeedback, setServicefeedback] = useState("");
  const [error, setError] = useState("");
  const [companylist, setCompanyList] = useState("");
  const [productlist, setProductList] = useState("");
  const [productdata, setProductData] = useState("");
  const [servicedata, setServiceData] = useState("");
  const [isproduct, setIsproduct] = useState(true);
  const [isservice, setIsservice] = useState(false);
  const [id, setId] = useState(false);
  useEffect(() => {
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getProductFeedback&forceno=${state.data.ppono}`,
    })
      .then((res) => {
        setProductData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getServiceFeedback&forceno=${state.data.ppono}`,
    })
      .then((res) => {
        setServiceData(res.data.data);
      })

      .catch((error) => {
        console.log(error);
      });
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getFeedbackCompany`,
    })
      .then((res) => {
        setCompanyList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getFeedbackProduct&id=${id}`,
    })
      .then((res) => {
        setProductList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [error, id]);

  const handleProduct = () => {
    setError("");
    if (company && product && feedback) {
      let array = JSON.stringify([
        {
          category: "product",
          company: company,
          product: product,
          feedback: feedback,
          forceNo: state.data.ppono,
        },
      ]);
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=putProductFeedback&data=${array}`,
      })
        .then((res) => {
          setError(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleId = (e) => {
    const items = e.target;
    for (let i = 0; i < items.length; i++) {
      let element = items[i];
      if (element.selected) {
        console.log(element.value)
        setCompany(element.value)
        setId(element.id);
        break;
      }
    }
  };
  const handleService = () => {
    setError("");
    if (serviceFeedback && serviceFeedback) {
      let array = JSON.stringify([
        {
          category: "service",
          feedback: serviceFeedback,
          forceNo: state.data.ppono,
        },
      ]);
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=putServiceFeedback&data=${array}`,
      })
        .then((res) => {
          setError(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const visibility_p = () => {
    setIsproduct(true);
    setIsservice(false);
  };
  const visibility_s = () => {
    setIsservice(true);
    setIsproduct(false);
  };
  
  return (
    <div className="container">
      <div className="d-flex justify-content-start mb-2">
        <button className="btn border" onClick={visibility_p}>
          <b>Products</b>
        </button>
        <button className="btn border" s onClick={visibility_s}>
          <b>Services</b>
        </button>
      </div>
      <div className={isproduct ? "" : "hiddens"}>
        <div className="d-flex justify-content-around">
          <div className="mb-3 row">
            <label for="canteenDataList" className="form-label">
              Company Name
            </label>
            <div className="col">
              <select
                className="form-control"
                id="canteenDataList"
                placeholder="Type company..."
                onChange={(e) => handleId(e)}
              >
                <option selected>Select Company</option>
                {companylist &&
                  companylist.map((value) => {
                    return (
                      <option id={value.VendorId} value={value.VendorName}>
                        {value.VendorName}
                      </option>
                    );
                  })}
              </select>
              {/* <input
                className="form-control"
                id="canteenDataList"
                placeholder="Type company..."
                onChange={(e) => setCompany(e.target.value)}
              ></input> */}
            </div>
          </div>
          <div className="mb-3 row">
            <label for="categoryDataList" className="form-label mr-4">
              Product Name
            </label>
            <div className="col">
              <select
                list="productOptions"
                className="form-control"
                id="categoryDataList"
                placeholder="Type product..."
                onChange={(e) => setProduct(e.target.value)}
              >
              <option selected>Select Product</option>
                {productlist &&
                  productlist.map((value) => {
                    return (
                      <option value={value.ItemName}>{value.ItemName}</option>
                    );
                  })}
              </select>
              {/* <input
                list="productOptions"
                className="form-control"
                id="categoryDataList"
                placeholder="Type product..."
                onChange={(e) => setProduct(e.target.value)}
              ></input>
              <datalist id="productOptions">
                {productlist &&
                  productlist.map((value) => {
                    return (
                      <option value={value.ItemName}>{value.ItemName}</option>
                    );
                  })}
              </datalist> */}
            </div>
          </div>
          <div className="mb-3 row">
            <label for="categoryDataList" className="form-label mr-4">
              Feedback
            </label>
            <div className="col">
              <textarea
                className="form-control"
                placeholder="Type your feedback..."
                onChange={(e) => setFeedback(e.target.value)}
                id="exampleFormControlTextarea1"
                rows="1"
              ></textarea>
            </div>
          </div>
          <div className="mb-3 row">
            <div className="mt-4 col">
              <button className="mt-2 btn" id="bgcolor" onClick={handleProduct}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <h7>{error}</h7>
        </div>
        <table className="table container t_body mt-3">
          <thead className="t_head">
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Company Name</th>
              <th scope="col">Product Name</th>
              <th scope="col">Feedback</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {productdata &&
              productdata.map((value) => {
                return (
                  <tr>
                    <td className="counterCell"></td>
                    <td>{value.company}</td>
                    <td>{value.product}</td>
                    <td>{value.feedback}</td>
                    <td>{value.date}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className={isservice ? "" : "hiddens"}>
        <div className="d-flex justify-content-evenly align-items-center">
          <div className="mb-3 row">
            <label for="categoryDataList" className="form-label mr-4">
              Feedback
            </label>
            <div className="col">
              <textarea
                className="form-control"
                placeholder="Type your feedback..."
                onChange={(e) => setServicefeedback(e.target.value)}
                id="exampleFormControlTextarea1"
                rows="2"
              ></textarea>
            </div>
          </div>
          <div className="mb-3 row">
            <div className="mt-4 col">
              <button className="mt-2 btn" id="bgcolor" onClick={handleService}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <h7>{error}</h7>
        </div>
        <table className="table container t_body mt-3">
          <thead className="t_head">
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Feedback</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {servicedata &&
              servicedata.map((value) => {
                return (
                  <tr>
                    <td className="counterCell"></td>
                    <td>{value.feedback}</td>
                    <td>{value.date}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Feedback;
