import axios from "axios";
import React, { useEffect, useState } from "react";
import "../styles/supplier.css";
// import sup_img from "../img/supplier_img.jfif";

function Supplier({ handleClickOpen }) {
  const [data, setData] = useState("");
  const [supsearch, setSupSearch] = useState(null);

  useEffect(() => {
    if (supsearch === null) {
      var urls = `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getcompany`;
    } else {
      urls = `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getcompanybasedname&companyname=${supsearch}`;
    }
    axios({
      url: urls,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [supsearch]);
  const url = `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getitemdetailsusingid&id`;
  return (
    <>
      <div className="d-flex justify-content-between container pro_head">
        <div className="prohead">Supplier Details</div>
        <div class="row align-items-center">
          <label for="exampleDataList" class="col-sm-4 col-form-label">
            Suppliers&nbsp;Name
          </label>
          <div class="col-sm-8">
            <input
              class="form-control"
              id="exampleDataList"
              placeholder="Type to search..."
              onChange={(e) => setSupSearch(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="supplierdiv">
        {data &&
          data.map((value) => {
            return (
              <div key={value.vendorid} className="sup_box">
                <div className="supimg">
                  {/* <img alt="sup_img" className="sup_img" src={sup_img} /> */}
                  <img alt="supplier_logo" className="sup_img" src={`https://kpkbgcavadi.in/CRPF/crpf_vendor_logo/${value.vendorid}.jpg`} onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src ="http://kpkbgcavadi.in/CRPF/crpf_vendor_logo/error_img.jfif")
          )
        }/>
                </div>
                <div className="sup_title">{value.vendorname}</div>
                <div className="btnss">
                  <button
                    className="btn btn_color"
                    onClick={handleClickOpen(
                      "paper",
                      value.vendorid,
                      value.vendorname,
                      url
                    )}
                  >
                    Product
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Supplier;
