import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../styles/stockrequest.css";

function Stockrequest() {
  const state = useSelector((state) => state);
  const [canteen, setCanteen] = useState("");
  const [category, setCategory] = useState("");
  const [item, setItem] = useState("");
  const [itemname, setItemname] = useState("");
  const [price, setPrice] = useState("");
  const [locationid, setLocationid] = useState("");
  const [typeid, setTypeid] = useState("");
  const [itemid, setItemid] = useState("");
  const [qty, setQty] = useState("");
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    try {
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getRequest&forceno=${state.data.ppono}`,
      }).then((res) => {
        setData(res.data.data);
      });
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getcanteendetailsmobile`,
      }).then((res) => {
        setCanteen(res.data.data);
      });
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=groupmaster`,
      }).then((res) => {
        setCategory(res.data.data);
      });
      if (locationid && typeid) {
        axios({
          url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getproductsbylocationrequest&locationid=${locationid}&typeid=${typeid}`,
        }).then((res) => {
          setItem(res.data.data);
        });
      }
      if (itemname) {
        axios({
          url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getproductsbypackage&ItemName=${itemname}`,
        }).then((res) => {
          setPrice(res.data.data);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [typeid,itemname,error,locationid,state]);
  const handleAdd = () => {
    setError("");
    if (itemid && locationid && state.data.ppono) {
        axios({
          url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=checkordermobile&itemid=${itemid}&qty=${qty}&locationid=${locationid}&forceno=${state.data.ppono}`,
        }).then((res) => {
          setError(res.data.status);
        });
    }
  };
  const handleItem = (e) => {
    const items = e.target;
    for (let i = 0; i < items.length; i++) {
      let element = items[i];
      if (element.selected) {
        setItemid(element.id);
        setItemname(element.value);
        break
      }
    }
  };
  return (
    <div className="container">
      <div className="stockdiv">
        <div style={{marginRight:"10px"}}>
          <label for="exampleDataList" class="form-label">
            Canteen&nbsp;Name
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => setLocationid(e.target.value)}
          >
            <option selected>select canteen</option>
            {canteen &&
              canteen.map((data) => {
                return <option value={data.locationid}>{data.loca}</option>;
              })}
          </select>
        </div>
        <div style={{marginRight:"10px"}}>
          <label for="exampleDataList" class="form-label">
            Category
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => setTypeid(e.target.value)}
          >
            <option selected>select category</option>
            {category &&
              category.map((data) => {
                return <option value={data.GroupId}>{data.GroupName}</option>;
              })}
          </select>
        </div>
        <div style={{marginRight:"10px"}}>
          <label for="exampleDataList" class="form-label">
            Item&nbsp;Name
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => handleItem(e)}
          >
            <option selected>select item</option>
            {item &&
              item.map((data) => {
                return (
                  <option value={data.ItemName} id={data.itemid}>
                    {data.ItemName}
                  </option>
                );
              })}
          </select>
        </div>
        <div style={{width:"150px"}}>
          <label for="exampleDataList" class="form-label">
            MRP
          </label>
          <input
            class="form-control"
            disabled
            value={price && price[0].MRP}
          ></input>
        </div>
        <div style={{width:"150px",marginRight:"10px"}}>
          <label for="exampleDataList" class="form-label">
            Rate
          </label>
          <input
            class="form-control"
            disabled
            value={price && price[0].rate}
          ></input>
        </div>
        <div style={{width:"100px",marginRight:"10px"}}>
          <label for="exampleDataList" class="form-label">
            Qty
          </label>
          <input
            class="form-control"
            type={"number"}
            min="1"
            onChange={(e) => setQty(e.target.value)}
          />
        </div>
        <div>
          <button className="btn btn-primary mt-4" onClick={handleAdd}>
            Add
          </button>
        </div>
      </div>
      <div>
        <small>{error}</small>
      </div>
      <table className="table container t_body mt-5">
        <thead className="t_head">
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Location Name</th>
            <th scope="col">Item Name</th>
            <th scope="col">MRP</th>
            <th scope="col">Rate</th>
            <th scope="col">Qty</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((value) => {
              return (
                <tr>
                  <td className="counterCell"></td>
                  <td>{value.LocationName}</td>
                  <td>{value.ItemName}</td>
                  <td>{value.MRP}</td>
                  <td>{value.Rate}</td>
                  <td>{value.Qty}</td>
                  <td>{value.amount}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default Stockrequest;
