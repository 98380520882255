import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import Products from "./Products";
import sup_img from "../img/supplier_img.jfif";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [data, setData] = React.useState("");
  const [value, setValue] = React.useState("");
  const [t_head, setThead] = React.useState(0);
  const state = useSelector((state) => state);
  const history = useNavigate();
  React.useEffect(() => {
    if (state.isAuthenticate === false) history("/");
  });
  const handleClickOpen = (scrollType, item_id, url, value) => async () => {
    setThead(["S.No.","Code", "Canteen Name", "Stock Available"]);
    setValue(value);
    await axios({
      url: `${url}=${item_id}`,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Products handleClickOpen={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="50%"
      >
        <DialogTitle id="scroll-dialog-title">
          {/* <b>{title}</b> */}
          <div className="pro_detail_div">
            <div>
              <dl>
                <dt>
                  <b>Code: </b>
                </dt>
                <dd>{value.ItemId}</dd>
                <dt>
                  <b>Product: </b>
                </dt>
                <dd>{value.ItemName}</dd>
                <dt>
                  <b>Brand: </b>
                </dt>
                <dd>{value.Brand}</dd>
                <dt>
                  <b>MRP Rate: </b>
                </dt>
                <dd>{value.MRP}</dd>
                <dt>
                  <b>Sale Rate: </b>
                </dt>
                <dd>{value.SaleRate}</dd>
              </dl>
            </div>
            <div>
              <img alt="product" className="sup_img" src={sup_img} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <>
              <table className="table container t_body">
                <thead className="t_head">
                  <tr>
                    {t_head &&
                      t_head.map((value) => {
                        return (
                          <th key={value} scope="col">
                            {value}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((value) => {
                      return (
                        <tr key={value.ItemId} style={{ fontSize: "small" }}>
                          <td className="counterCell"></td>
                          <td>{value.ItemId}</td>
                          <td>{value.LocationName}</td>
                          <td>{value.Stock}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
