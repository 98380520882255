import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../styles/canteen.css"

function Canteen() {
  const [data, setData] = useState("");
  const state = useSelector((state) => state);
  const history = useNavigate();
  useEffect(() => {
    if (state.isAuthenticate === false) history("/");
  });
  useEffect(() => {
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getcanteendetails`,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data]);

  return (
    <>
      <table className="table container t_body table-striped">
        <thead className="t_head">
          <tr>
          <th scope="col">S.No.</th>
            <th scope="col">Canteen Name</th>
            <th scope="col">Contact</th>
            <th scope="col">Mobile No</th>
            <th scope="col">Address</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((value) => {
              return (
                <tr>
                  <td className="counterCell"></td>
                  <td>{value.locationname}</td>
                  <td>{value.loca}</td>
                  <td>{value.mobileno}</td>
                  <td>{value.address}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export default Canteen;
