import "../styles/login.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Forgetpage = () => {
  const state = useSelector((state) => state);
  const [code, setCode] = useState("");
  const [usertype, setUsertype] = useState("");
  const [error, setError] = useState("");
  const history = useNavigate();

  useEffect(() => {
    if (state.isAuthenticate) history("/dashboard");
  });
  const dispatch = useDispatch();
  const handlesubmit = () => {
    setError("")
    if (code && usertype) {
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=sendforgototpmobile&cpsno=${code}&usertype=${usertype}`,
      })
        .then((res) => {
          const auth = res.data.response;
          if (auth === "Otp Sent") {
            dispatch({
                type: "FORGET",
                payload: {
                  code: code,
                  usertype: usertype,
                },
              });
              dispatch({
                type: "FROM",
                payload: {
                  from:"forget"
                },
              });
            history("/otp");
          } else {
            setError(auth)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const formvalidate = (e, arg) => {
    let x = document.getElementById(arg).value;
    if (x === "") {
      document.getElementById(arg).className =
        "is-invalid form-control mb-3 pr-5";
    } else {
      document.getElementById(arg).className = "form-control mb-3 pr-5";
    }
  };
  return (
    <div className="logindiv">
      <div className="border bg">
        <h1 className="mb-4 color">Forget Password</h1>
        <div className="form-group">
          <div>
          <label className="d-flex small" name="Force/PPO No.">
          Force/PPO No.
            </label>
            <input
              type="text"
              className="form-control mb-3 pr-5"
              placeholder="Force/PPO No."
              id="code"
              onBlur={(e) => {
                formvalidate(e, "code");
              }}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div>
          <label className="d-flex small" name="usertype">
          Usertype
            </label>
            <select
              className="form-select mb-5 pr-5"
              aria-label="Default select example"
              id="usertype"
              onBlur={(e) => {
                formvalidate(e, "usertype");
              }}
              onChange={(e) => setUsertype(e.target.value)}
            >
              <option selected>Select user type</option>
              <option value="First User">First User</option>
              <option value="Second User">Second User</option>
            </select>
          </div>
          <div>
            <small>{error}</small>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-baseline mt-4">
          <div>
            <button
              onClick={handlesubmit}
              className="btn shadow-none"
              id="bgcolor"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpage;
