import "../styles/login.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Forgetpassword = () => {
  const state = useSelector((state) => state);
  const [newpassword, setNewpassword] = useState("");
  const [conpassword, setConpassword] = useState("");
  const [error, setError] = useState("");
  const history = useNavigate();
  let code = state.forget.code;
  let usertype = state.forget.usertype;
  useEffect(() => {
    if (state.isAuthenticate) history("/dashboard");
  });
  const dispatch = useDispatch();
  const handlesubmit = () => {
    setError("");
    if (newpassword && conpassword && newpassword===conpassword) {
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=resetpasswordmobile&cpsno=${code}&usertype=${usertype}&reset_pass=${newpassword}`,
      })
        .then((res) => {
          const auth = res.data.status;
          if (auth === "Succes") {
            dispatch({
                type: "LOGIN",
                payload: {
                  usertype: usertype,
                  ppono: code,
                },
              });
              history("/dashboard");
          } else {
            setError(auth);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const formvalidate = (e, arg) => {
    let x = document.getElementById(arg).value;
    if (x === "") {
      document.getElementById(arg).className =
        "is-invalid form-control mb-3 pr-5";
    } else {
      document.getElementById(arg).className = "form-control mb-3 pr-5";
    }
  };
  return (
    <div className="logindiv">
      <div className="border bg">
        <h1 className="mb-4 color">Change Password</h1>
        <div className="form-group">
          <div>
            <label className="d-flex small" name="New Password">
              New Password
            </label>
            <input
              type="text"
              className="form-control mb-3 pr-5"
              placeholder="Enter New Password"
              id="newpassword"
              onBlur={(e) => {
                formvalidate(e, "newpassword");
              }}
              onChange={(e) => setNewpassword(e.target.value)}
            />
          </div>
          <div>
          <div>
            <label className="d-flex small" name="Confirm Password">
              Confirm Password
            </label>
            <input
              type="text"
              className="form-control mb-3 pr-5"
              placeholder="Confirm Password"
              id="confirmpassword"
              onBlur={(e) => {
                formvalidate(e, "confirmpassword");
              }}
              onChange={(e) => setConpassword(e.target.value)}
            />
          </div>
          </div>
          <div>
            <small>{error}</small>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-baseline mt-4">
          <div>
            <button
              onClick={handlesubmit}
              className="btn shadow-none"
              id="bgcolor"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
