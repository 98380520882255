import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Supplier from "./Supplier";
import axios from "axios";
import Pop from "./Pop";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [data, setData] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [from, setFrom] = React.useState(0);
  const [t_head, setThead] = React.useState(0);
  const state = useSelector((state) => state);
  const history = useNavigate();
  React.useEffect(() => {
    if (state.isAuthenticate === false) history("/");
  });
  const handleClickOpen =
    (scrollType, ven_id, ven_name, url) =>
    async () => {
      setTitle(ven_name);
      if (ven_name === null) {
        setFrom(1);
        setThead(["S.No.","Code", "Canteen Name", "Stock Available"]);
        
      } else {
        setFrom(0);
        setThead(["S.No.","Item Name", "MRP", "Rate", "Stock"]);
      }
      await axios({
        url: `${url}=${ven_id}`,
      })
        .then((res) => {
          console.log(res)
          setData(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setOpen(true);
      setScroll(scrollType);
    };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Supplier handleClickOpen={handleClickOpen} />
      {/* <Button onClick={handleClickOpen('paper')}>scroll=paper</Button>
      <Button onClick={handleClickOpen('body')}>scroll=body</Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="50%"
      >
        <DialogTitle id="scroll-dialog-title">
          <b>{title}</b>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Pop
              data={data}
              handleClickOpen={handleClickOpen}
              from={from}
              t_head={t_head}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
