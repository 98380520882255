import "../styles/signup.css";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Profile() {
  const [ismobiles, setIsmobiles] = useState(window.innerWidth < 700);
  const [error, setError] = useState("");
  const [data, setData] = useState("");
  const state = useSelector((state) => state);
  const history = useNavigate();
  useEffect(() => {
    if (state.isAuthenticate === false) history("/");
  });
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 700;
        if (ismobile !== ismobiles) setIsmobiles(ismobile);
      },
      false
    );
  }, [ismobiles]);
  useEffect(() => {
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getprofiledetails&cpsno=${state.data.ppono}&usertype=${state.data.usertype}`,
    })
      .then((res) => {
        const data = res.data;
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[state]);

  const formvalidate = () => {
    setError("");
    let list = ["name", "username", "email", "address", "district"];
    list.forEach((states) => {
      let x = document.getElementById(states).value;
      if (x === "") {
        document.getElementById(states).className =
          "is-invalid form-control mb-2 pr-5 mb-sm-0";
      }
    });
    if (
      data.name &&
      data.mobileno &&
      data.address &&
      data.district &&
      data.emailid
    ) {
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=userprofileupdate&name=${data.name}&address=${data.address}
&district=${data.district}&emailid=${data.emailid}&phoneno=&mobileno=${data.mobileno}&cpsno=${data.cpsno}&usertype=${data.usertype}`,
      })
        .then((res) => {
          const auth = res.data.status;
          if (auth === "Succes") {
            setError("Updated Successfully");
          } else {
            setError(auth);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="d-flex justify-content-center mb-4">
      <div
        className="p-2 flex-fill"
        style={{ display: `${ismobiles ? "none" : ""}` }}
      >
        &nbsp;
      </div>
      <div className={`${ismobiles ? "flex-fill" : "flex-fill"}`}>
        <div className="border pb-3 pt-2 pr-3 pl-3 bg">
          <h3 className="color">Profile</h3>
          <form>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="Force/PPO No.">
                    <b>Force/PPO No.</b>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="Force/PPO No."
                    id="code"
                    pattern="[A-Za-z]{1,20}"
                    value={data.cpsno}
                    onChange={(e) =>
                      setData({ ...data, cpsno: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="name">
                    <b>Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="Name"
                    id="name"
                    pattern="[A-Za-z]{1,20}"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="User name (Mobile No)">
                    <b>User Name (Mobile No)</b>
                  </label>
                  <input
                    type="tel"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="User name"
                    pattern="[0-9]{10}"
                    id="username"
                    value={data.mobileno}
                    onChange={(e) =>
                      setData({ ...data, mobileno: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="email">
                    <b>Email</b>
                  </label>
                  <input
                    type="email"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="email id"
                    id="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    value={data.emailid}
                    onChange={(e) =>
                      setData({ ...data, emailid: e.target.value })
                    }
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="Address">
                    <b>Address</b>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="Address"
                    id="address"
                    value={data.address}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="District">
                    <b>District</b>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="District"
                    id="district"
                    value={data.district}
                    onChange={(e) =>
                      setData({ ...data, district: e.target.value })
                    }
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${ismobiles ? "" : "row"}`}>
              <div className="col mb-3">
                <fieldset>
                  <label className="d-flex small" name="User type">
                    <b>User Type</b>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control mb-2 pr-5 mb-sm-0"
                    placeholder="User type"
                    id="usertype"
                    value={data.usertype}
                  />
                </fieldset>
              </div>
            </div>
            <div>{error !== null ? error : ""}</div>
          </form>
          <button
            className="btn shadow-none mt-3"
            type="submit"
            name="sign up"
            id="bgcolor"
            onClick={formvalidate}
          >
            Update
          </button>
        </div>
      </div>
      <div
        className="p-2 flex-fill"
        style={{ display: `${ismobiles ? "none" : ""}` }}
      >
        &nbsp;
      </div>
    </div>
  );
}

export default Profile;
