import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../styles/dashboard.css";

function Dashboard() {
  const state = useSelector((state) => state);
  const history = useNavigate();
  useEffect(() => {
    if (state.isAuthenticate === false) history("/");
  });
  return (
    <>
      <div className="container">
        <div>
          <div className="title mb-4">Purchase limit for KPKB</div>
          <div className="slabdiv">
            <div className="slab_box">
              <div className="das_head">Officer - Ceiling</div>
              <div className="das_value"><b>Monthly - </b>Rs.20,000/-</div>
              <div className="das_value mt-2"><b>Yearly - </b>Rs.1,00,000/-</div>
            </div>
            <div className="slab_box">
              <div className="das_head">SOs - Ceiling</div>
              <div className="das_value"><b>Monthly - </b>Rs.10,000/-</div>
              <div className="das_value mt-2"><b>Yearly - </b>Rs.1,00,000/-</div>
            </div>
            <div className="slab_box">
              <div className="das_head">ORs - Ceiling</div>
              <div className="das_value"><b>Monthly - </b>Rs.7,000/-</div>
              <div className="das_value mt-2"><b>Yearly - </b>Rs.80,000/-</div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5 notice">
            <div>Home appliances limit yearly once</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
