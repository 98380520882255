import React from 'react'

function Pop({data,handleClickOpen,from,t_head}) {

    const url=`https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getstock&id`
  return (
    <><table className="table container t_body">
    <thead className="t_head">
      <tr>
        {t_head&&t_head.map(value=>{
            return(
                <th scope="col">{value}</th>
            )
        })}
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((value) => {
          return (
            from===0?
            <tr style={{fontSize:"small"}}>
              <td className="counterCell"></td>
              <td>{value.Itemname}</td>
              <td>{value.MRP}</td>
              <td>{value.Rate}</td>
              {/* <td>{value.VendorName}</td> */}
              <td><b className={value.stock==="0"?'text-danger':'text-success' } 
              // onClick={handleClickOpen('paper',value.Itemid,null,url)}
              >{value.stock}</b></td>
            </tr>:<tr style={{fontSize:"small"}}>
            <td className="counterCell"></td>
              <td>{value.Itemid}</td>
              {/* <td>{value.LocationName}</td> */}
              <td>{value.stock}</td>
            </tr>
          );
        })}
    </tbody>
  </table></>
  )
}

export default Pop