import "../styles/login.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

const Changepassword = () => {
  const state = useSelector((state) => state);
  const [oldpassword, setOldpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [error, setError] = useState("");
  const history = useNavigate();
  let code = state.data.ppono;
  let usertype = state.data.usertype;
  useEffect(() => {
    if (!state.isAuthenticate) history("/");
  });
  const handlesubmit = () => {
    setError("");
    if (oldpassword && newpassword) {
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=changepasswordmobile&cpsno=${code}&usertype=${usertype}&old_pass=${oldpassword}&new_pass=${newpassword}`,
      })
        .then((res) => {
          const auth = res.data.response;
          setError(auth);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const formvalidate = (e, arg) => {
    let x = document.getElementById(arg).value;
    if (x === "") {
      document.getElementById(arg).className =
        "is-invalid form-control mb-3 pr-5";
    } else {
      document.getElementById(arg).className = "form-control mb-3 pr-5";
    }
  };
  return (
    <div className="logindiv">
      <div className="border bg">
        <h1 className="mb-4 color">Change Password</h1>
        <div className="form-group">
          <div>
            <label className="d-flex small" name="Old Password">
              <b>New Password</b>
            </label>
            <input
              type="text"
              className="form-control mb-3 pr-5"
              placeholder="Enter old password"
              id="oldpassword"
              onBlur={(e) => {
                formvalidate(e, "oldpassword");
              }}
              onChange={(e) => setOldpassword(e.target.value)}
            />
          </div>
          <div>
            <div>
              <label className="d-flex small" name="New Password">
                <b>Confirm Password</b>
              </label>
              <input
                type="text"
                className="form-control mb-3 pr-5"
                placeholder="New Password"
                id="newpassword"
                onBlur={(e) => {
                  formvalidate(e, "newpassword");
                }}
                onChange={(e) => setNewpassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <small>{error}</small>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-baseline mt-4">
          <div>
            <button
              onClick={handlesubmit}
              className="btn shadow-none"
              id="bgcolor"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;
