import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Main from "./components/Main";
// import Nav from "./components/Nav";
import Test from "./components/Test";
import Canteen from "./components/Canteen";
// import Supplier from "./components/Supplier";
import Popup from "./components/Popup";
import Productpopup from "./components/Productpopup";
import Profile from "./components/Profile";
import Signup from "./components/Signup";
import Forgetpage from "./components/Forgetpage";
import Otp from "./components/Otp";
import Forgetpassword from "./components/Forgetpassword";
import Changepassword from "./components/Changepassword";
import Reqandfeed from "./components/Reqandfeed";
import Canteenstock from "./components/Canteenstock";
// import Testcurtain from "./components/Testcurtain";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Nav /> */}
        <Test />
        <div style={{ marginTop: "200px",zIndex:"0" }}>
          <Routes>
            {/* <Route index element={ <Testcurtain/>} /> */}
            <Route index element={<Main />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="login" element={<Login />} />
            <Route path="canteen" element={<Canteen />} />
            <Route path="supplier" element={<Popup />} />
            <Route path="products" element={<Productpopup />} />
            <Route path="canteenstock" element={<Canteenstock />} />
            <Route path="User Profile" element={<Profile />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgetpage" element={<Forgetpage />} />
            <Route path="otp" element={<Otp />} />
            {/* <Route path="one" element={<Otp />} /> */}
            <Route path="forgetpassword" element={<Forgetpassword />} />
            <Route path="Change Password" element={<Changepassword />} />
            <Route path="Request/Feedback/*" element={<Reqandfeed />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
