import "../styles/login.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Otp = () => {
  const state = useSelector((state) => state);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const history = useNavigate();

  useEffect(() => {
    if (state.from === "" ) history("/");
  });
  let mobile = state.signup.mobile;
  let fromSignup = state.from;
  let fromForget = state.from;
  let code = state.forget.code;
  let usertype = state.forget.usertype;
  const dispatch = useDispatch();
  const handlesubmit = () => {
    setError("");
    if (otp) {
      if (fromSignup === "signup") {
        // dispatch({
        //   type: "CLEARFROM",
        // });
        code = state.signup.code;
        usertype = state.signup.usertype;
        axios({
          url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=verifyotpmobile&mobileno=${mobile}&otp=${otp}`,
        })
          .then((res) => {
            const auth = res.data.otpverifyresponse;
            if (auth === "success") {
              dispatch({
                type: "LOGIN",
                payload: {
                  usertype: usertype,
                  ppono: code,
                },
              });
              history("/dashboard");
            } else {
              setError(auth);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (fromForget === "forget") {
        // dispatch({
        //   type: "CLEARFROM",
        // });
        axios({
          url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=resetotpmobile&cpsno=${code}&usertype=${usertype}&reset_otp=${otp}`,
        })
          .then((res) => {
            const auth = res.data.response;
            if (auth === "1") {
              history("/forgetpassword");
            } else {
              setError(auth);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const resendOtp = () => {
    if (fromSignup === "signup") {
      mobile = state.signup.mobile;
      axios({
        url: ` http://kpkbgcavadi.in/CRPF/apicontroller.php?action=sendmsgmobile&mobileno=${mobile}`,
      })
        .then((res) => {
          setError(res.data.smsresponse);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (fromForget === "forget") {
      let code = state.forget.code;
      let usertype = state.forget.usertype;
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=sendforgototpmobile&cpsno=${code}&usertype=${usertype}`,
      })
        .then((res) => {
          setError(res.data.response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const formvalidate = (e, arg) => {
    let x = document.getElementById(arg).value;
    if (x === "") {
      document.getElementById(arg).className =
        "is-invalid form-control mb-3 pr-5";
    } else {
      document.getElementById(arg).className = "form-control mb-3 pr-5";
    }
  };
  return (
    <div className="logindiv">
      <div className="border bg">
        <h2 className="mb-4 color">OTP VERFICATION</h2>
        <div className="form-group">
          <div>
            <input
              type="text"
              className="form-control mb-3 pr-5"
              placeholder="Enter the OTP"
              id="otp"
              onBlur={(e) => {
                formvalidate(e, "otp");
              }}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <div>
            <small>{error}</small>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-baseline mt-4">
          <div className="color">
            <button onClick={resendOtp} className="btn">
              Resend OTP
            </button>
          </div>
          <div>
            <button
              onClick={handlesubmit}
              className="btn shadow-none"
              id="bgcolor"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
