import "../styles/main.css";
// import CM from "../img/CM.jfif";
import dig_gc_avdi from "../img/dig_gc_avdi.jpg";
import product from "../img/product.png";
import play from "../img/play_store.png";
// import app from "../img/app_store.png";
import ProductScrolle from "./ProductScrolle";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function Main() {
  const state = useSelector((state) => state);
  const history = useNavigate();
  useEffect(() => {
    if (state.isAuthenticate) history("/dashboard");
  });
  return (
    <div>
      <div style={{ marginLeft: "4%", marginRight: "2%" }}>
        <ProductScrolle />
      </div>
      <div className="leftbox">
        <div>
          <div className="containers">
            <div>
              <div className="title mb-4">
                Welcome to Kendriya Police Kalyan Bhandhar,CRPF AVADI
              </div>
              <div className="content">
                The Kendriya Police Kalyan Bhandar (KPKB) at GC CRPF Avadi is a
                retail outlet which was initiated as a welfare venture by the
                Ministry of Home Affairs in the year 2006. The beneficiaries of
                KPKB are the serving and retired personnel of CAPF and their
                families across Tamil Nadu as well as nearby districts of
                Andhrapradesh. KPKB system has grown into a big, popular and
                successful organization. KPKB provides consumer goods of a wide
                range and variety to its beneficiaries at cheaper rates than the
                market.
              </div>
            </div>
          </div>
          <div className="containers mt-5">
            <div style={{ textAlign: "right" }}>
              <div className="title mb-4">Beneficiaries</div>
              <div className="content">
                As the DIGP cum President of the KPKB Subsidiary Bhandar GC
                Avadi, it gives me immense pleasure and pride that KPKB is self-
                sufficient and delivering in a very proficient and effective
                manner with minimum available resources and without having any
                Govt Fund. KPKB is catering for daily needs of its more than one
                lakh clientele belonging to State of Tamil Nadu. On our list of
                inventory we have almost all of the big brands available in
                India. KPKB Subsidiary Bhandar has a seamless and effective
                mechanism to deliver the goods in order to meet the requirements
                of its beneficiaries in the remotest part of the State of Tamil
                Nadu and nearby districts of Andhra Pradesh.
              </div>
            </div>
          </div>
        </div>
        <div className="leftboxdiv">
          <div className="leftbox2 mt-3">
            <img alt="person" className="chr" src={dig_gc_avdi} />
            <div className="chr_text">
              Sh.M.DHINAKARAN<br></br>DIGP CUM PRESIDENT <br></br> KPKB GC CRPF
              AVADI
            </div>
            </div>
            <div className="leftbox1 mt-3">
              <div>
                <div className="title_dow mt-4">Download App<br></br>Now</div>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=crpf.canteen.serviceskst"
                className="nav-link"
                rel="noreferrer"
                target={"_blank"}
              >
                <img className="download_img" alt="download" src={play}></img>
              </a>
              {/* <img alt="person" className="cm" src={dig_gc_avdi} /> */}
              {/* <img alt="person" className="cm" src={""} /> */}
            </div>
          
        </div>
      </div>

      <div className="containers product mt-3 mb-5">
        <div>
          <div className="title mb-4">Products</div>
          <div className="content">
            The KPKB inventory has a wide spectrum of product groups including
            Cosmetics, Toiletries, Electrical and Electronic Appliances, Kitchen
            Appliances, Packaged Foods, Grocery items, Uniform Clothes and
            Accoutrements, Lifestyle products, Foot wears, Hosiery and Textiles
            and Automobiles and Helmets.The Central Purchase Committee is
            successfully negotiating with the firms to get maximum discounts
            along with consumer promotional offers on products in order to save
            hard earned money of KPKB beneficiaries who are serving the nation
            in challenging conditions
          </div>
        </div>
        <div className="boximg">
          <img alt="product" src={product} />
        </div>
      </div>
      {/* <div className="containers hell mt-3 mb-5">
        <div>
          <div className="title_dow mb-4">Download Now</div>
        </div>
        <div className="d-flex">
          <div className="mr-5">
            <a
              href="https://play.google.com/store/apps/details?id=crpf.canteen.serviceskst"
              className="nav-link"
              rel="noreferrer"
              target={"_blank"}
            >
              <img className="download_img" alt="download" src={play}></img>
            </a>
          </div>
          <div>
            <img className="download_img" alt="download" src={app}></img>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Main;
