import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/ProductScrolle.css"
import img1 from "../img/banner_3.png" 
import img2 from "../img/banner_5.png"
// import img3 from "../img/img2.jpg"
import img4 from "../img/img4.jpg" 
import img3 from "../img/banner_4.png" 
// import img5 from "../img/banner_5.png" 
// import img1 from "../img/img1.jpg" 
// import img2 from "../img/img2.jpg" 
// import img3 from "../img/img3.jpg" 
// import img4 from "../img/img4.jpg" 
// import img5 from "../img/img5.jpg" 

const ProductScrolle = () => {
    const state = {
        items: [
          {id: 1, title: img1},
          {id: 2, title: img2},
          {id: 3, title: img3},
          {id: 4, title: img4},
          // {id: 5, title: img5}
        ]
      }
      const { items } = state;
  return (
    <Carousel autoPlay infiniteLoop showThumbs={false} showIndicators onSwipeMove={true}>
    {items.map(item => <div key={item} className='banner'><img className='banner_img'  style={{borderRadius:"7px",userSelect:"none"}} alt='ad' src={item.title} key={item.id}></img></div>)}
  </Carousel>
  )
}

export default ProductScrolle