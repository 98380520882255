const initialState = {
  isAuthenticate: false,
  data: {
    usertype: "",
    ppono: "",
    name:""
  },
  signup: {
    code: "",
    usertype: "",
    mobile: ""
  },
  forget: {
    code: "",
    usertype: ""
  },
  from:"",
  show:false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticate: true,
        data: {
          usertype: action.payload.usertype,
          ppono: action.payload.code,
          name: action.payload.name,
        },
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticate: false,
        data: {
          usertype: "",
          ppono: "",
        },
      };
    case "SIGNUP":
      return {
        ...state,
        isAuthenticate: false,
        signup: {
          code: action.payload.code,
          usertype: action.payload.usertype,
          mobile: action.payload.mobile,
        },
      };
    case "FORGET":
      return {
        ...state,
        isAuthenticate: false,
        forget: {
          code: action.payload.code,
          usertype: action.payload.usertype,
        },
      };
    case "FROM":
      return {
        ...state,
        from:action.payload.from,
      };
    case "CLEARFROM":
      return {
        ...state,
        from:"",
      };
    case "LAUNCH":
      return {
        ...state,
        show:action.payload.show,
      };
    default:
      return { ...state };
  }
}
