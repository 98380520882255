import logo from "../img/CRPF-logo.jpeg";
import ashoka from "../img/ashoka.png";
import "../styles/test.css";
import { Outlet, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Profile from "./ProfileMenu";

function Nav() {
  const state = useSelector((state) => state);
  const Grocery = {
    1: "Rice & Rice Product",
    3: "Flours",
    6: "Sugar & Sweetners",
    8: "Sauces & Ketchup",
    2: "Dals",
    4: "Oil & Ghee",
    7: "Masala & Spices",
    5: "Tea & Coffee",
    11: "Health Drinks & Health Care",
    13: "Juices",
    10: "Ready to Cook & Eat",
    9: "Pickles",
    12: "Snacks",
    59: "Millets",
    61: "Cool Drinks",
  };
  const grocery = Object.keys(Grocery);
  const HK = {
    21: "Pooja Items",
    22: "Cookwares",
    23: "Kitchenware",
    24: "Gas Appliances",
    25: "Home Furnishing",
    26: "Furnitures",
    27: "Cleaning Accessories",
    29: "Room Freshner",
    30: "Bathroom Accessories",
    28: "Mops & Brushes & Scrubs",
    57: "Glassware",
  };
  const hk = Object.keys(HK);
  const HA = {
    31: "Television",
    32: "Refrigerator",
    35: "Washing Machine",
    33: "Airconditioners",
  };
  const ha = Object.keys(HA);

  const ME = {
    45: "Mobile Phones",
    56: "Music Player",
    52: "Watches",
    34: "Air Coolers",
    37: "Grinders",
    38: "Mixers",
    39: "Electrical",
    40: "Fans",
    36: "Water Purifier & Heater",
  };
  const me = Object.keys(ME);
  const PC = {
    14: "Oral Care",
    15: "Soaps",
    16: "Shampoos",
    17: "Cosmetics",
    18: "Deo & Perfumes",
    19: "Skincare",
    20: "Detergents & Dishwash",
  };
  const pc = Object.keys(PC);
  const LS = { 42: "Clothing", 44: "Grooming", 43: "Footwear", 58: "Sports" };
  const ls = Object.keys(LS);
  return (
    <div className="nav_fix">
      <div className="navbars">
        <div>
          <img alt="logo" className="logo" src={logo}></img>
        </div>
        <div>
          <div className="App-link">
            <b>
              <span className="titles">KENDRIYA POLICE KALYAN BHANDAR</span>
            </b>
            <br></br>
            <small>
              <b>GROUP CENTRE CRPF AVADI</b>
            </small>
            <br></br>
            <small>
              <b>MINISTRY OF HOME AFFAIR</b>
            </small>
            <br></br>
            <b>GOVT OF INDIA</b>
          </div>
        </div>
        <div>
          <img alt="ashoka" className="ashoka" src={ashoka} />
        </div>
      </div>

      <nav className="navbar navbar-expand-lg menuname mb-4">
        <div className="container-fluid">
          <div className="navbabrand">
            {" "}
            {state.isAuthenticate ? (
              ""
            ) : (
              <ul className="navbar-nav  me-auto mb-2 mb-lg-0 profile_log">
                <li className="nav-item">
                  <a
                    href="https://play.google.com/store/apps/details?id=crpf.canteen.serviceskst"
                    style={{ opacity: 1 }}
                    className="nav-link ps"
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    <img
                      alt="play_store"
                      style={{ marginBottom: "4px" }}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAD5ElEQVRIia2TW2icRRiG329mNpvmuE1TaZJNrTbFaKUovfCmN6IieFFMEJTSgtrWQi5UqpKiKUZEvRFKoMFGoQqmN4GCSpQQPJEDbQ0RtCWmJDG1OTRpuxu6Ofynmfm82D+bbEo0bTPw8f4zA+/zv/PNEAC8MjjwPZddqtawJ1qLXzqJdRwEALU9LkdjV4eCLee3CiGuQIj32mL7zq4rwDKgiseG/YreCgu7QQjRD9ZvfFdyqPeeATU9DjMDFoAovD7qVf5Y6rMpFERGgb4xkt/pjB0evWvA890LzABsCKG8mxPu/R35PpmYhoWAcCJErYG09T2xupk7B/QuJbCc3rC5icn5B9qjAcymgA00DCSJmSipJq9EfdxPR4K1AMQihQAQh0QwyCkp3/B3rQaLKQuGBeBYvTGhnUb/xvzlx6+feHXNCWp7FtgCYAYWjyqtDBtdSCYfbJt34FYaMDRbBDCwYCgS/bkiUj9Q+tZP/51AAEQrldPq55XEhl8sVDbnqmVGOg1Dw2LO+rsTZqHz3MSez92JXQ+tChDMUGBIMAQYkhkCgOT0WkTnxrYM79uobHTYwoYQCwKbdv/rvl3mj8PWjv3pjJe1cKp6020AJQhCECSFJQBJgBAEFa4rzi3cOnKgQpm8AQsGweqOoLX/EZ56AmAQeTlEM68Fs9eGgun4+8xV0UwP9p93ePHMl/cgux9pDdj3rmz78vK3tslW0MxjRBaZgoUIv6Hye9Xm1J50AgIkcajpuSJAhntqmRZpi7aGblGW9AURI1NgCLE4B7Q7azNHJAWgxJIqma0y1HzjOI0th0ZjN5OPzh7fvpOn8n/LMgfDMBKuyw3ROD+dAUSIEQGQQ2EBiKzQYjc139D88mTBXLJagiEtSaexajeNF5wTgmEN+56H5gVPV+Vvsx8Rwc/04PXfXbZh1zPnH75sZqBgNnmr7uTBVK7vVsrFW8YMyQDBGj7612e6fK7pvp3e8MprqgAgRyyZMoUQCs1TN24dbD4yGw28SklpU8GAAEFb3acD/+3yZxJdqz00BQBREumbQksQZiCWmEzsO1Xnq8CLSzAUCMQMw3bINfaDys7eM6sZZwFkmEAsMy+ZHpl+oeWoVCYoE2AoANraaaP5k7gnm+nXbv1/5hkAgDQhlM1jA9dqTtfnKWOKBRhgOE4QfOHPBMd3XLiQWotxFoCXVdnopfG9X71bJNkWMXHgBqbNWBzb0dEzfifG2QnCv48P9f3z3JkPS8Em39P6Z9+aNx/+oevi3RjflmD7xa6RJ89+Gtc6GAyAY9Xtv3Tci3HWOHW6vWOo5tmBwb1P7V8303D8C1kNHFvWL2fqAAAAAElFTkSuQmCC"
                    />
                    <b> DOWNLOAD APP NOW</b>
                  </a>
                </li>
              </ul>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            {state.isAuthenticate ? (
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            ) : (
              ""
            )}
            <div className="profile_nav">
              {state.isAuthenticate ? (
                <Profile />
              ) : (
                <div>
                  <Link className="login_btn" id="bgcolor" to="/login">
                    <i className="bx bx-user"></i> Login
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {state.isAuthenticate ? (
              <>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      to="/dashboard"
                      state={[41, "Baby Products"]}
                      className="nav-link"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle test"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Canteen
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to="/canteen" className="dropdown-item">
                        List of Canteen
                      </Link>
                      <Link to="/canteenstock" className="dropdown-item">
                        Canteen Stock{" "}
                      </Link>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link to="/supplier" className="nav-link">
                      Supplier
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Grocery
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {grocery.map((value) => {
                        return (
                          <Link
                            to="/products"
                            state={[value, Grocery[value]]}
                            className="dropdown-item"
                          >
                            {Grocery[value]}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Home & Kitchen
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {hk.map((value) => {
                        return (
                          <Link
                            to="/products"
                            state={[value, HK[value]]}
                            className="dropdown-item"
                          >
                            {HK[value]}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Home Appliances
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {ha.map((value) => {
                        return (
                          <Link
                            to="/products"
                            state={[value, HA[value]]}
                            className="dropdown-item"
                          >
                            {HA[value]}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Mobile & Electronics
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {me.map((value) => {
                        return (
                          <Link
                            to="/products"
                            state={[value, ME[value]]}
                            className="dropdown-item"
                          >
                            {ME[value]}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Personal Care
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {pc.map((value) => {
                        return (
                          <Link
                            to="/products"
                            state={[value, PC[value]]}
                            className="dropdown-item"
                          >
                            {PC[value]}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/products"
                      state={[41, "Baby Products"]}
                      className="nav-link"
                    >
                      Baby Products
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Life Style
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {ls.map((value) => {
                        return (
                          <Link
                            to="/products"
                            state={[value, LS[value]]}
                            className="dropdown-item"
                          >
                            {LS[value]}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Travel Accessories
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        to="/products"
                        state={[49, "Helmets"]}
                        className="dropdown-item"
                      >
                        Helmets
                      </Link>
                      <Link
                        to="/products"
                        state={[50, "Bags"]}
                        className="dropdown-item"
                      >
                        Bags
                      </Link>
                      <Link
                        to="/products"
                        state={[48, "Umbrella"]}
                        className="dropdown-item"
                      >
                        Umbrella
                      </Link>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Vehicle
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        to="/products"
                        state={[54, "Bicycles"]}
                        className="dropdown-item"
                      >
                        Bicycles
                      </Link>
                    </ul>
                  </li>
                </ul>

                <div
                  className="profile_avatar"
                  // onClick={logout}
                >
                  {/* <i className="bx bx-user"></i> Logout */}
                  <Profile />
                </div>
              </>
            ) : (
              <>
                <ul className="navbar-nav  me-auto mb-2 mb-lg-0 profile_log">
                  <li className="nav-item"></li>
                </ul>
                <div className="profile_log">
                  <Link className="login_btn" id="bgcolor" to="/login">
                    <i className="bx bx-user"></i> Login
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
}

export default Nav;
