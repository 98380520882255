import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function Wishlist() {
  const state = useSelector((state) => state);
  const [company, setCompany] = useState("");
  const [product, setProduct] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState("");
  useEffect(() => {
    axios({
      url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=getWishList&forceno=${state.data.ppono}`,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [error,state]);

  const handlesave = () => {
    setError("");
    if (company && product) {
      axios({
        url: `https://kpkbgcavadi.in/CRPF/apicontroller.php?action=addwishlist&prodname=${product}&companyname=${company}&cpsno=${state.data.ppono}`,
      })
        .then((res) => {
          setError(res.data.response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <div className="d-flex justify-content-around container">
        <div class="mb-3 row">
          <label for="canteenDataList" class="form-label">
            Company Name
          </label>
          <div class="col">
            <input
              class="form-control"
              id="canteenDataList"
              placeholder="Type to search..."
              onChange={(e) => setCompany(e.target.value)}
            ></input>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="categoryDataList" class="form-label mr-4">
            Product Name
          </label>
          <div class="col">
            <input
              class="form-control"
              id="categoryDataList"
              placeholder="Type to search..."
              onChange={(e) => setProduct(e.target.value)}
            ></input>
          </div>
        </div>
        <div class="mb-3 row">
          <div className="mt-4 col">
            <button className="mt-2 btn" id="bgcolor" onClick={handlesave}>
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <small>{error}</small>
      </div>
      <table className="table container t_body mt-3">
        <thead className="t_head">
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Company Name</th>
            <th scope="col">Product Name</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((value) => {
              return (
                <tr>
                  <td className="counterCell"></td>
                  <td>{value.CompanyName}</td>
                  <td>{value.ProductName}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export default Wishlist;
